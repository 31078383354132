import React from "react"

import { Page } from "../components/layout"
import SEO from "../components/seo"

class TermsPage extends React.Component {
  render() {
    return (
      <Page>
        <SEO title="Crédits" />
        <div className="page page__credits">
          <div className="page__header">
            <h1 className={"h__title"}>Pro and&nbsp;co</h1>
            <h2 className={"h__title h__title--secondary"}>Crédits et&nbsp;mentions&nbsp;légales</h2>
          </div>
          <div className="page__credits__main">
            <h4>Éditeur du site</h4>
            <p>
              Pro and co
              <br />237, chemin du coteau
              <br />01600 Reyrieux (France)
            </p>

            <h4>Mail</h4>
            <p>contact@pro-and-co.fr</p>

            <h4>Siret</h4>
            <p>80369580800011</p>

            <h4>Forme juridique</h4>
            <p>SARL. Enregistrée à l'INSEE le 21-07-2014</p>

            <h4>Responsable éditorial</h4>
            <p>
              Alexia et Michel Protsenko
              <br />237, chemin du coteau
              <br />01600 Reyrieux (France)
            </p>

            <h4>Fournisseur hébergement et domaine</h4>
            <p>
              - Nom de domaine pro-and-co.fr enregistré chez 
              <a href="https://gandi.net" target={"_blank"}
                 rel={"noreferrer noopener"}>Gandi</a>
              <br />- Hébergement web par <a href="https://netlify.app" rel={"noreferrer noopener"} target={"_blank"}>Netlify</a>
            </p>

            <h4>Web design</h4>
            <p><a href="https://samuel-rambaud.fr" target={"_blank"} rel={"noopener noreferrer"}>Samuel Rambaud</a>, <a
              href="https://violy.net" target={"_blank"} rel={"noopener noreferrer"}>Arthur Violy</a></p>

            <h4>Photographie (architecture)</h4>
            <p>Sarah Ruggiero</p>


            <h4>Contenu</h4>
            <p>
              On entend par contenu du site la structure générale, les textes, les images animées ou non dont le site
              est composé. Pro and co se réserve le droit de modifier ou de corriger le contenu de ce site à tout moment
              et sans préavis.
            </p>

            <h4>Propriété intellectuelle</h4>
            <p>
              Conformément au code de la propriété intellectuelle,
              <br/>l’internaute et le client disposent exclusivement :
              <br/>– D’un droit d’usage privé sur le contenu du site ou de l’un de ses éléments ;
              <br/>– D’un droit de reproduction
              pour stockage aux fins de représentation sur un écran mono poste et de reproduction, en un exemplaire,
              pour copie de sauvegarde ou tirage sur papier. Tout autre usage est soumis à l’autorisation express
              préalable de la société Pro and co. Dès lors, l’internaute et le client ne doivent en aucun cas faire de
              publications ou communications sans mentionner le nom de la société Pro and co. Il en est ainsi de toute
              représentation et/ou reproduction, même partielle du contenu de ce site à des fins commerciales et/ou
              publicitaires et/ou de distribution.
            </p>

            <h4>Droits d’auteur</h4>
            <p>
              Les photographies, textes, slogans, dessins, images, ainsi que toutes les oeuvres intégrées dans le site
              sont la propriété la société Pro and co. Les reproductions, sur un support papier ou informatique, dudit
              site sont autorisées sous réserve qu’elles soient strictement réservées à un usage personnel. Les
              reproductions à des fins commerciales ou publicitaires sont interdites sans l’autorisation de la société
              Pro and co, et constituent une contrefaçon.

            </p>

          </div>
        </div>
      </Page>
    )
  }
}

export default TermsPage
